<template>
  <div class="content-container">
    <BaseH1 :text="'Zmiana hasła nie jest możliwa'" :short="true" />
    <SvgIllustrationsCross />
    <TextDescription>
      Niestety, <b>ten link już wygasł</b>. Kliknij przycisk poniżej, aby wysłać
      nowy link
    </TextDescription>
    <ButtonLink @click="handleTryAgain" :label="'Try again'" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME } from "@/router/constants";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsCross from "@/components/Svg/Illustrations/SvgIllustrationsCross.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

const ROUTE_FORGOT_PASSWORD = {
  name: ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME,
};

export default {
  components: {
    BaseH1,
    TextDescription,
    SvgIllustrationsCross,
    ButtonLink,
  },

  setup() {
    const router = useRouter();
    const handleTryAgain = () => {
      router.push(ROUTE_FORGOT_PASSWORD);
    };

    return {
      handleTryAgain,
    };
  },
};
</script>

<style scoped>
.content-container {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 80px;
}

@media (max-width: 1200px) {
  .content-container {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 80px;
  }
}
</style>
