<template>
  <svg
    width="52"
    height="68"
    viewBox="0 0 52 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.4085 0.700623C46.3289 1.31476 51.2048 5.10778 49.3971 8.17107C48.4081 9.84693 48.0421 10.2085 47.1576 11.0822C46.4254 11.8055 45.3378 12.8799 43.2472 15.3416C34.5289 25.6078 30.9666 32.5065 28.8247 36.8168C30.7455 38.4167 32.8133 40.5159 35.2007 42.9396C36.006 43.7571 36.8475 44.6114 37.7321 45.496C41.6502 49.4142 44.6143 52.1485 46.7968 54.1619C51.2796 58.2973 52.4652 59.3911 51.8483 61.4529C50.9311 64.5185 46.2493 67.7406 43.886 66.9764C41.8002 66.3019 27.3694 50.0853 24.0097 46.2868C23.627 45.8542 22.9856 45.8575 22.6405 46.3206C21.4484 47.9207 19.0307 51.6346 16.2551 57.4621C15.4164 59.223 14.7398 60.8469 14.1491 62.2644C12.5789 66.0326 11.6161 68.3433 9.83084 67.8971C9.73768 67.8738 9.63745 67.8492 9.531 67.8231C6.83028 67.1599 0.128574 65.5143 3.37496 59.6095C3.69211 59.0326 4.07221 58.329 4.50488 57.5281C7.19529 52.5482 11.9188 43.805 16.185 38.3563C15.1736 37.115 13.8443 35.5474 11.9313 33.578C10.3284 31.9279 8.32302 29.9392 6.42538 28.0574C3.35153 25.0091 0.560311 22.2412 0.220023 21.6462C-0.913168 19.6648 2.5661 14.758 5.28133 15.3416C7.56075 15.8316 17.9616 26.4468 21.2416 29.8418C21.726 30.3432 22.5601 30.216 22.8727 29.5928C24.5523 26.2446 29.351 16.8977 34.8373 8.17966C41.3075 -2.10179 44.3034 -0.0542151 45.3187 0.639699C45.3506 0.661482 45.3805 0.681931 45.4085 0.700623Z"
      fill="#D83E3E"
    />
  </svg>
</template>
